import L from 'leaflet';
L.MeasureControl = L.Control.extend({
    initialize(options = {}) {
        const defaultOptions = {
            position: 'topleft',
            formatOutput: output => `${output || ''}`,
            lineColor: 'black',
            lineWeight: 3,
            lineOpacity: 0.9,
            innerHtml: '⇢'
        };
        L.Util.setOptions(this, defaultOptions);
        L.Util.setOptions(this, options);
    },

    onAdd(map) {
        const className = 'leaflet-control-zoom leaflet-bar leaflet-control',
              container = L.DomUtil.create('div', className);
        this._createButton('leaflet-control-measure leaflet-bar-part leaflet-bar-part-top-and-bottom', container);
        return container;
    },

    azimuth(points) {

        const λ1 = points[0].lng, 
              λ2 = points[1].lng, 
              φ1 = points[0].lat,
              φ2 = points[1].lat;
    
        const tmp = Math.sin((λ2-λ1)*Math.PI/180) / ((Math.cos(φ1*Math.PI/180) * Math.tan(φ2*Math.PI/180)) - (Math.sin(φ1*Math.PI/180) * Math.cos((λ2-λ1)*Math.PI/180)))
        const B = Math.atan(tmp)*(180/Math.PI);
    
        let result = B;
    
        result = B < 0 ? B + 360 : B + 180;
          
        if(points[0].lng < points[1].lng) 
            result = result < 180 ? result + 180 : result - 180; 
        else if(points[0].lng === points[1].lng && points[0].lat <= points[1].lat) 
            result = 0;
    
        return result;
    },
    

    _createButton: function (className, container) {
        const button = L.DomUtil.create('a', className, container);
        button.innerHTML = this.options.innerHtml;
        button.addEventListener('click', () => this._toogleMeasure());
        L.DomEvent.disableClickPropagation(button);
    },

    _toogleMeasure: function () {
        this._measuring = !this._measuring;
        if (this._measuring) {
            L.DomUtil.addClass(this._container, 'leaflet-control-measure-on');
            this._startMeasure();
        } else {
            L.DomUtil.removeClass(this._container, 'leaflet-control-measure-on');
            this._stopMeasure();
        }
    },

    _startMeasure: function () {
        this._oldCursor = this._map._container.style.cursor;
        this._map._container.style.cursor = 'crosshair';

        L.DomEvent.on(this._map, 'click', this._mouseClick, this);
    },

    _stopMeasure: function () {
        this._map._container.style.cursor = this._oldCursor;

        this._clear();

        L.DomEvent.off(this._map, 'click', this._mouseClick, this);
    },

    _mouseClick: function (e) {
        if (!e.latlng) {
            return;
        }

        this._draw(e);
    },

    _draw(e) {
        if (!this._start) {
            this._start = new L.marker(e.latlng).addTo(this._map);
        } else if (!this._end) {
            this._end = new L.marker(e.latlng).addTo(this._map);

            this._path = new L.Polyline([this._start.getLatLng(), this._end.getLatLng()], {
                color: this.options.lineColor,
                weight: this.options.lineWeight,
                opacity: this.options.lineOpacity,
                smoothFactor: 1
            }).addTo(this._map);
            
            let distance = e.latlng.distanceTo(this._start.getLatLng());
            let azimuth = this.azimuth([this._start.getLatLng(), this._end.getLatLng()])
            this._tooltip = this._createTooltip(e.latlng, this.options.formatOutput(distance, azimuth));
        } else {
            this._clear();
            this._draw(e);
        }
    },

    _clear() {
        if (this._start) {
            this._map.removeLayer(this._start);
            this._start = undefined;
        }

        if (this._end) {
            this._map.removeLayer(this._end);
            this._end = undefined;
        }

        if (this._path) {
            this._map.removeLayer(this._path);
            this._path = undefined;
        }

        if (this._tooltip) {
            this._map.removeLayer(this._tooltip);
            this._tooltip = undefined;
        }
    },

    _createTooltip: function (position, text) {
        var icon = L.divIcon({
            className: 'leaflet-measure-tooltip',
            iconAnchor: [-5, -5]
        });

        this.tooltip = L.marker(position, {
            icon: icon,
            clickable: false
        }).addTo(this._map);

        this.tooltip._icon.innerHTML = text;

        return this.tooltip;
    }
});

L.measureControl = options => new L.MeasureControl(options);
