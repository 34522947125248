import { useEffect } from 'react';
import { useLeafletContext } from '@react-leaflet/core';
import { useMap } from 'react-leaflet';

import TimeControl from './timeline-control';
import Timeline from './timeline';
import './timeline.css';

/*
  Interested links
    http://danielmontague.com/projects/easyButton.js/v1/examples/
    https://stackoverflow.com/questions/62947152/react-leaflet-v3-custom-control


*/


// https://stackoverflow.com/questions/62947152/react-leaflet-v3-custom-control
// TODO: update layers!

const TimelineControl = (props) => {
  const context = useLeafletContext();
  const map = useMap();

  // const [container, setContainer] = useState(undefined);
  // const [control, setControl] = useState(undefined);
  // const [timeline, setTimeline] = useState(undefined);


  // useEffect(() => {
  //   let _container;
  //   const controlLayers = props.controlRef && props.controlRef.current;
  //   if (!container) {
  //     _container = context.layerContainer || context.map;

  //     let _timeline;
  //     let _control;

  //     if (controlLayers) {
  //       _timeline = Timeline(props.geoJSON, controlLayers, {...props.timelineOptions}, (...a) => map.setView(...a));
  //       _control = TimeControl(_timeline, props.timecontrolOptions);

  //       _container.addControl(_timeline);
  //       _container.addControl(_control);
  //     }

  //     setContainer(_container);
  //     setTimeline(_timeline);
  //     setControl(_control);
  //   }
  //   else {
  //     container.removeControl(timeline);
  //     container.removeControl(control);

  //     timeline.update(props.geoJSON, controlLayers, props.timelineOptions);
  //     control.update(timeline, props.timecontrolOptions);
  //   }
  // }, [props]);

  // useEffect(() => () => {
  //   console.log("TimeLine Effect", timeline, control)
  //   if (timeline) {
  //     container.removeControl(timeline);
  //     // timeline.removeFrom(map);
  //   }    
  //   if (control)
  //     container.removeControl(control);
  // });


  // useEffect(() => {
  //   console.log("TimeLine Update")
  //   // let _container;
  //   // const controlLayers = props.controlRef && props.controlRef.current;
  //   // if (!container) {
  //   //   _container = context.layerContainer || context.map;

  //   //   let _timeline;
  //   //   let _control;

  //   //   if (controlLayers) {
  //   //     _timeline = Timeline(props.geoJSON, controlLayers, {...props.timelineOptions}, (...a) => map.setView(...a));
  //   //     _control = TimeControl(_timeline, props.timecontrolOptions);

  //   //     _container.addControl(_timeline);
  //   //     _container.addControl(_control);
  //   //   }

  //   //   setContainer(_container);
  //   //   setTimeline(_timeline);
  //   //   setControl(_control);
  //   // }
  //   // else {
  //   //   container.removeControl(timeline);
  //   //   container.removeControl(control);

  //   //   timeline.update(props.geoJSON, controlLayers, props.timelineOptions);
  //   //   control.update(timeline, props.timecontrolOptions);
  //   // }
  // }, [props]);


  useEffect(() => {
    const container = context.layerContainer || context.map;
    const controlLayers = props.controlRef && props.controlRef.current;

    const _timeline = Timeline(props.geoJSON, controlLayers, {...props.timelineOptions}, (latlngs) => map.fitBounds(latlngs, { padding: [100, 100], maxZoom: 15 }));
    const _control = TimeControl(_timeline, props.timecontrolOptions);

    container.addControl(_control);
    container.addControl(_timeline);

    return () => {
      container.removeControl(_control);
      container.removeControl(_timeline);
    };
  });

  return null;
};

export default TimelineControl;