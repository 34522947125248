import { useReducer, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import GpsOn from '@material-ui/icons/GpsFixed';
import GpsOff from '@material-ui/icons/GpsNotFixed';
import GpsOut from '@material-ui/icons/GpsOff';
import { useLeafletContext } from '@react-leaflet/core';
import L from 'leaflet';
import Control from './LeafletControl';

const useStyles = makeStyles(theme => ({
  div: {
    background:'white',
    borderRadius: '4px'
  },
  button: {
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    width: '34px',
    height: '34px',
    border: '2px solid rgba(0,0,0,0.4)',
    borderRadius: '4px'
  },
  icon: {
    fontSize: '18px',
    padding: '8px',
    color: 'black'
  },
  locationIcon: {
    backgroundColor: '#4285F4',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: 'white',
    marginLeft: '-10px !important',
    marginTop: '-10px !important',
    width: '16px !important',
    height: '16px !important',

  }
}));

const initialState = {
  marker: null,
  geoId: null,
  position: null,
  status: 0
};

const locationReducer = (state, action) => {
  switch(action.type) {
    case 'LOCATION_UPDATE':
      return {
        ...state,
        position: action.payload.position,
        geoId: action.payload.geoId
      };
    case 'LOCATION_ON':
      return {
        ...state,
        status: 1
      };
    case 'LOCATION_MARKER':
      return {
        ...state,
        marker: action.payload.marker,
      };
    case 'LOCATION_OFF':
      return {
        ...state,
        status: 0
      };
    case 'LOCATION_CLEAN':
      return {
        ...state,
        marker: null,
        geoId: null,
        position: null,
      };
    case 'LOCATION_ERROR':
      return {
        ...state,
        marker: null,
        geoId: null,
        position: null,
        status: -1
      };
    default:
      throw new Error();
  }
};

const LocationControl = (props) => {
  const classes = useStyles();
  const context = useLeafletContext();
  const [state, dispatch] = useReducer(locationReducer, initialState);
  const { map } = context;

  useEffect(() => {
    if (state.status === 1) {
      const geoId = navigator.geolocation.watchPosition(position => {
        dispatch({type: 'LOCATION_UPDATE', payload: {
          position: position,
          geoId: geoId
        }});
      }, _ => {
        dispatch({type: 'LOCATION_ERROR'});
      }, {
        enableHighAccuracy: false
      });
    } else {
      if (state.marker && state.geoId) {
        navigator.geolocation.clearWatch(state.geoId);
        map.removeLayer(state.marker);
        dispatch({type: 'LOCATION_CLEAN'});
      }
    }
  }, [state.status]);

  useEffect(() => {
    if (state.position !== null) {
      const { latitude, longitude, accuracy } = state.position.coords;
      let locationMarker;
      if(state.marker === null) {
        const innerCircle = L.marker([latitude, longitude], {
          icon: L.divIcon({className: classes.locationIcon})
        });
        const circle = L.circle([latitude, longitude], {
          opacity: 0.4,
          fillOpacity: .1,
          weight: 1,
          radius: accuracy
        });
  
        locationMarker = L.layerGroup([innerCircle, circle]).addTo(map);
        map.setView(new L.LatLng(latitude, longitude), 18);
      } else {
        locationMarker = state.marker;
        locationMarker.eachLayer((layer) => {
          if (layer instanceof L.Marker) {
            layer.setLatLng([latitude, longitude]);
          } else if (layer instanceof L.Circle) {
            layer.setLatLng([latitude, longitude]);
            layer.setRadius(accuracy);
          }
        });
      }
      dispatch({type: 'LOCATION_MARKER', payload: {marker: locationMarker}})
    }
  }, [state.position])

  const toggleLocation = () => {
    if (state.status === 0) {
      dispatch({type: 'LOCATION_ON'});
    } else {
      dispatch({type: 'LOCATION_OFF'});
    }
  };

  let icon;
  switch (state.status) {
    case 0:
      icon = <GpsOn className={classes.icon} />;
      break;
    case 1:
      icon = <GpsOff className={classes.icon} />;
      break;
    default:
      icon = <GpsOut className={classes.icon} />
      break;
  }

  return (
    <Control position='topleft'>
      <div className={classes.div}>
        <Button 
          disableRipple
          {...props}
          className={classes.button}
          onClick={toggleLocation}
        >
          {icon}
        </Button>
      </div>
    </Control>
  );
};

export default LocationControl;