import React from 'react';
import Card from '@material-ui/core/Card';
// import BoatIcon from '@material-ui/icons/DirectionsBoat';
import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'react-admin';
import { ListItem, ListItemText, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import moment from 'moment';
import Link from '../../components/Link';

import IconCitizen from './IconCitizen'
import CardIcon from './CardIcon';

const useStyles = makeStyles(
  theme => ({
    root: {
      width: '100%',
      maxWidth: 360,
    },
    main: {
      flex: '1',
      // marginRight: '1em',
      marginTop: 20,
    },
    main_mobile: {
      flex: '1',
      // marginRight: '1em',
      marginTop: 20,
    },
    card: {
      overflow: 'inherit',
      textAlign: 'right',
      padding: 16,
      minHeight: 52,
    },
    inline: {
      display: 'inline',
    },
    cardIcon: {
      float: 'left',
      margin: '-20px 20px 0 15px',
      zIndex: 100,
      borderRadius: 3,
      border: '2px solid #003bb0'
    }
  })
);

const PublicNextCampaigns = ({ value, mobile, ...rest }) => {
  const classes = useStyles(rest);
  const translate = useTranslate();
  return (
    <div className={mobile ? classes.main_mobile : classes.main}>
      <CardIcon style={{
        // width: 30,
        // height: 30,
        // handColor: '#000',
        // wavyColor: '#000'
      }}
        Icon={IconCitizen}
        bgColor="#c2cbe3"
        cardIcon={classes.cardIcon}
      />
      <Card className={classes.card}>

        <Typography className={classes.title} color="textSecondary">
          {translate('containers.dashboard.next_campaigns')}
        </Typography>

        <Typography type="headline" component="h2">
          {(value && value.length) || 0}
        </Typography>

        {value && value.map((campaign, index) => (
          <Link
            to={`${campaign.permlink}`}
            key={index}
          >
            <List className={classes.root} key={index}>
              <ListItem
                className={classes.content}
              >
                <ListItemText
                  primary={`${campaign.name}`}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {'Start date: '}
                      </Typography>
                      {/* {moment(campaign.begintimestamp).utc().format('DD-MM-YYYY, HH:mm:ss') + ' GMT'} */}
                      {new Date(campaign.begindate).toLocaleDateString()}
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
          </Link>
        ))}

      </Card>
    </div>
  )
};

export default PublicNextCampaigns;