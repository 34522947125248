import {
  LayersControl as RLayersControl,
  TileLayer
} from 'react-leaflet';
import { useRef, Fragment, cloneElement } from 'react';

import GoogleLayer from 'react-leaflet-google-layer';
import configuration from '../../configuration';

const zoomConfig = {
  maxNativeZoom: 19,
  maxZoom: 23,
  minZoom: 3
}

const LayersControl = (props) => {
  const controlRef = useRef();

  let children;
  if (props.children) {
    children = cloneElement(props.children, { controlRef });
  }

  return (
    <Fragment>
      <RLayersControl position='topright' ref={controlRef}>
        <RLayersControl.BaseLayer checked name="Open Street Map">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            maxNativeZoom={zoomConfig.maxNativeZoom}
            maxZoom={zoomConfig.maxZoom}
            minZoom={zoomConfig.minZoom}
          />
        </RLayersControl.BaseLayer>
        <RLayersControl.BaseLayer name="Open Street Map Black/White">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png'
            maxNativeZoom={zoomConfig.maxNativeZoom}
            maxZoom={zoomConfig.maxZoom}
            minZoom={zoomConfig.minZoom}
          />
        </RLayersControl.BaseLayer>
        <RLayersControl.BaseLayer name="ESRI Satellite">
          <TileLayer
            attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
            url='http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
            maxNativeZoom={zoomConfig.maxNativeZoom}
            maxZoom={zoomConfig.maxZoom}
            minZoom={zoomConfig.minZoom}
          />
        </RLayersControl.BaseLayer>
        <RLayersControl.BaseLayer name="Google Maps Satellite">
          <GoogleLayer
            apiKey={configuration.MAP_GOOGLE_KEY}
            type='satellite'
            maxNativeZoom={zoomConfig.maxNativeZoom}
            maxZoom={zoomConfig.maxZoom}
            minZoom={zoomConfig.minZoom}
          />
        </RLayersControl.BaseLayer>


        {/* <RLayersControl.BaseLayer name="Google Maps Hybrid">
          <GoogleLayer
            apiKey={configuration.MAP_GOOGLE_KEY}
            type='hybrid'
            maxNativeZoom={zoomConfig.maxNativeZoom}
            maxZoom={zoomConfig.maxZoom}
            minZoom={zoomConfig.minZoom}
          />
        </RLayersControl.BaseLayer> */}
      </RLayersControl>

      {children}
    </Fragment>
  );
};

export default LayersControl;