import { toObservationData } from '../../factory'; 

export const FETCH_OBSERVATIONS = 'FETCH_OBSERVATIONS';
export const CLEAR_OBSERVATIONS = 'CLEAR_OBSERVATIONS';
export const STORE_OVERLAYS = 'STORE_OVERLAYS';
export const SELECT_CAMPAIGN = 'SELECT_CAMPAIGN';

export const fetchObservations = (data) => {
  return ({
    type: FETCH_OBSERVATIONS,
    payload: toObservationData(data)
  });
};

export const clearObservations = () => {
  return ({
    type: CLEAR_OBSERVATIONS
  });
}

export const storeOverlays = (overlays) => {
  return ({
    type: STORE_OVERLAYS,
    payload: overlays
  });
};

export const selectCampaign = (campaigns) => {
  return ({
    type: SELECT_CAMPAIGN,
    payload: campaigns
  });
};
