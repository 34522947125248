import { useContext, useCallback } from 'react';
import { PageContext } from './Context';

const useSetCampaign = () => {
  const { setCampaign } = useContext(PageContext);
  return useCallback(
    (newState) =>
        setCampaign(newState),
    [setCampaign]
  );
};

export default useSetCampaign;