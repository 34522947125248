import englishMessages from 'ra-language-english';

export default {
  ...englishMessages,
  http: {
    fail_request: 'Load request failed'
  },
  resources: {
    launches: {
      name: 'Launch |||| Launches' ,
      fields: {
        beginTimestamp: 'Begin Date',
        endTimestamp: 'End Date',
      },
    },
    equipment: {
      name: 'Equipment |||| Equipment' ,
      fields: {
        state: "State",
        name: 'Name',
        serialNumber: 'Serial Number',
        number: 'Number',
        equipmentmodelId: 'Model',
      },
    }
  },

  containers: {
    dashboard: {
      next_launches: 'Next planned Launches',
      active_launches: 'Active Launches',
      past_launches: 'Past Launches',
      next_campaigns: 'Next planned Campaigns',
      active_campaigns: 'Active Campaigns',
      past_campaigns: 'Past Campaigns',
    },
    live: {
      name: 'Live Map',
      overview: 'Last transmission'
    },
    history: {
      name: 'History Map',
      complete_interval: 'Complete interval',
      all_data: 'All data',
      no_data: 'There is no data on this launch'
    },
    profile: {
      name: 'Profile',
      fields: {
        name: 'Name',
        username: 'Username', 
        email: 'Email',
        old_password: "Old password",
        new_password: "New password",
        repeat_password: "Repeat password",
      },
      buttons: {
        change_password: 'Change password',
      },
      notifications: {
        profile_updated: 'Profile updated',
        password_updated: 'Password updated',
        wrong_password: 'Old password wrong',
        on_failure_profile: 'Save profile failled',
        on_failure_password: 'Error changing password',
        different_passwords: 'Needed to be iqual',
      }
    },
    configuration: {
      name: 'Configuration',
      language: {
        name: 'Language',
        en: 'English',
        'en-GB': 'English',
        pt: 'Português'
      },
      theme: {
        name: 'Theme',
        light: 'Light',
        dark: 'Dark',
      },
      notifications: {
        settings_updated: 'Configuration saved',
        on_failure_settings_update: 'Saving configuration failed',
      }
    },
  }
};
