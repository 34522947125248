import { useContext, useCallback } from 'react';
import { PageContext } from './Context';

const useSetPage = () => {
  const { setPage } = useContext(PageContext);
  return useCallback(
    (newState) =>
        setPage(newState),
    [setPage]
  );
};

export default useSetPage;