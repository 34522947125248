import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default (props) => {
  const history = useHistory();

  useEffect(() => {
    history.push('/');
  }, []);

  return null;
};

