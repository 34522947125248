import {
  UPDATE_PAGE,
  UPDATE_CAMPAIGN
} from '../actions/page';

const initialState = {
  page: undefined,
  campaignId: undefined
};

export default (state = initialState, action) => {
  switch (action.type) {

    case UPDATE_PAGE:
      return { ...state, page: action.payload };
    
    case UPDATE_CAMPAIGN:
      return { ...state, campaignId: action.payload };

    default:
      return state;
  }
};
