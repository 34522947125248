import {
  FETCH_OLD_OBSERVATIONS,
  LIVE_OBSERVATIONS,
  CLEAR_OBSERVATIONS,
} from '../actions/liveMap';

export default (state = [], action) => {
  switch (action.type) {

    case FETCH_OLD_OBSERVATIONS:
      return action.payload;

    case LIVE_OBSERVATIONS:
      let index = state.findIndex(item => item.wavy.id === action.payload.wavy.id);
      let copy = state.slice();

      if (index >= 0) {
        if (new Date(action.payload.wavy.last_transmission.timestamp) > new Date(copy[index].wavy.last_transmission.timestamp)) {
          copy[index].position.push(action.payload.position[0]);
          copy[index].wavy.last_transmission = action.payload.wavy.last_transmission;
        }
      } else {
        copy.push(action.payload);
      }

      return copy;
    case CLEAR_OBSERVATIONS:
      return [];
    default:
      return state;
  }
};
