import L from 'leaflet'
L.TimelineSliderControl = L.Control.extend({
  initialize(timeline, options = {}) {
    const defaultOptions = {
      enablePlayButton: true,
      enableStepButtons: true,
      formatOutput: output => `${output || ''} UTC`,
      showTicks: true,
      position: 'bottomleft',
      speeds: [1, 2, 4, 8, 16],
      legend: '',
      speedsUnity: [{
        seconds: 1,
        text: '1s'
      }, {
        seconds: 5,
        text: '5s'
      }, {
        seconds: 10,
        text: '10s'
      }, {
        seconds: 30,
        text: '30s'
      }, {
        seconds: 60,
        text: '1m'
      }, {
        seconds: 300,
        text: '5m'
      }, {
        seconds: 600,
        text: '10m'
      }, {
        seconds: 1800,
        text: '30m'
      }, {
        seconds: 3600,
        text: '1h'
      }, {
        seconds: 7200,
        text: '2h'
      }
      ]
    };

    this.timeline = timeline;
    this.interval = timeline.getIntervalValues();
    this.stepSize = 1000;
    this.speedUnityTime = defaultOptions['speedsUnity'][0];
    this.speedTime = defaultOptions['speeds'][0];
    L.Util.setOptions(this, defaultOptions);
    L.Util.setOptions(this, options);
  },
  update(timeline, options = {}) {
    this.timeline = timeline;
    this.interval = timeline.getIntervalValues();

    L.Util.setOptions(this, options);
  },
  
  getTimelineTime() {
    return this.data;
  },
  _createDOM() {
    const classes = [
      'leaflet-control-layers',
      'leaflet-control-layers-expanded',
      'leaflet-timeline-control',
    ];
    const container = L.DomUtil.create('div', classes.join(' '));
    this.container = container;

    L.DomEvent.disableClickPropagation(this.container);

    const sliderCtrlC = L.DomUtil.create(
      'div',
      'sldr-ctrl-container',
      container,
    );

    if ((this.options.enablePlayButton) || (this.options.enableStepButtons)) {
      const buttonContainer = L.DomUtil.create(
        'div',
        'button-container',
        sliderCtrlC,
      );
      this._makeButtons(buttonContainer);
    }

    this._makeOutput(sliderCtrlC);
    this._makeSlider(container);

    if (this.options.showTicks) {
      this._buildDataList(container);
    } else {
      this._buildLegend(container);
    }

  },
  _buildLegend(container) {

    this._legend = L.DomUtil.create('legend', '', container);
    this._legend.innerHTML = this.options.legend;
  },
  _makeButton(container, name) {
    const button = L.DomUtil.create('button', name, container);
    button.addEventListener('click', () => this[name]());
    L.DomEvent.disableClickPropagation(button);
  },
  _makeSpeedButton(container) {
    const button = L.DomUtil.create('button', 'speed', container);
    button.innerHTML = this.speedUnityTime.text;

    button.addEventListener('click', () => this.speed(button));
    L.DomEvent.disableClickPropagation(button);
  },
  _makeButtons(container) {
    if (this.options.enableStepButtons)
      this._makeButton(container, 'prev');

    if (this.options.enablePlayButton) {
      this._makeButton(container, 'play');
      this._makeButton(container, 'pause');
    }

    if (this.options.enableStepButtons)
      this._makeButton(container, 'next');
    // Speed button
    this._makeSpeedButton(container);
  },
  _makeSlider(container) {
    const slider = L.DomUtil.create('input', 'time-slider', container);
    slider.type = 'range';
    slider.min = this.interval.min || 0;
    slider.max = this.interval.max || 0;
    slider.value = this.interval.min || 0;
    slider.step = 1000;
    this._timeSlider = slider;
    // register events using leaflet for easy removal
    L.DomEvent.on(this._timeSlider, 'change input', this._sliderChanged, this);
    L.DomEvent.on(this._timeSlider, 'pointerdown mousedown touchstart', this._disableMapDragging, this);
    L.DomEvent.on(document, 'pointerup mouseup touchend', this._enableMapDragging, this);
  },
  _makeOutput(container) {
    this._output = L.DomUtil.create('output', 'time-text', container);
    this._output.innerHTML = this.options.formatOutput(this.interval.min);
  },
  _buildDataList(container) {
    this._datalist = L.DomUtil.create('datalist', '', container);
    const idNum = Math.floor(Math.random() * 1000000);
    this._datalist.id = `timeline-datalist-${idNum}`;
    this._timeSlider.setAttribute('list', this._datalist.id);
    this._rebuildDataList();
  },
  _rebuildDataList() {
    const datalist = this._datalist;
    while (datalist.firstChild) {
      datalist.removeChild(datalist.firstChild);
    }
    const datalistSelect = L.DomUtil.create('select', '', this._datalist);
    this.interval.steps.forEach((time) => {
      L.DomUtil.create('option', '', datalistSelect).value = time;
    });
  },
  _disableMapDragging() {
    this.map.dragging.disable();
  },
  _enableMapDragging() {
    this.map.dragging.enable();
  },
  _sliderChanged(e) {
    const time = parseFloat(+e.target.value, 10);
    this.time = time;
    this.map.time = time;

    if (this._output) {
      this._output.innerHTML = this.options.formatOutput(time);
    }

    this.timeline.updateDisplayedLayers(time);
  },
  _nearestStep(time, mode) {
    let lastTime = this.interval.steps[0];
    for (let i = 0; i < this.interval.steps.length; i++) {
      if (this.interval.steps[i] >= time) {
        if (mode === -1)
          return lastTime;

        if (this.interval.steps[i] !== time)
          return this.interval.steps[i];
      }
      lastTime = this.interval.steps[i];
    }
    return lastTime;
  },
  prev() {
    this.pause();
    const prevTime = this._nearestStep(this.time, -1);
    this._timeSlider.value = prevTime;
    this.setTime(prevTime);
  },
  play(fromSynced) {
    clearTimeout(this._timer);
    
    let miliseconds = 0;
    if(this.speedUnityTime.seconds > 10) {
      this._timeSlider.value = parseFloat(this._timeSlider.value, 10) + (this.speedUnityTime.seconds * 0.05 * this.stepSize);
      this.setTime(this._timeSlider.value);
      miliseconds = 50;
    } else {
      this._timeSlider.value = parseFloat(this._timeSlider.value, 10) + this.stepSize;
      this.setTime(this._timeSlider.value);
      miliseconds = 1000 / this.speedUnityTime.seconds;
    }
    
    if (parseFloat(this._timeSlider.value, 10) === this.interval.max) {
      this._playing = false;
      this.container.classList.remove('playing');
    } else {
      this._playing = true;
      this.container.classList.add('playing');
      this._timer = setTimeout(() => this.play(true), miliseconds);
    }
    if (this.syncedControl && !fromSynced) {
      this.syncedControl.map(function (control) {
        return control.play(true);
      });
    }
  },
  pause(fromSynced) {
    clearTimeout(this._timer);
    this._playing = false;
    this.container.classList.remove('playing');

    if (this.syncedControl && !fromSynced) {
      this.syncedControl.map(function (control) {
        return control.pause(true);
      })
    }
  },
  next(pause = true) {
    pause && this.pause();
    const nextTime = this._nearestStep(this.time, 1);
    this._timeSlider.value = nextTime;
    this.setTime(nextTime)
  },
  speed(button) {
    const speeds = this.options.speedsUnity;
    let i = speeds.indexOf(this.speedUnityTime);
    if (i >= speeds.length - 1) {
      i = 0;
    } else {
      i++;
    }
    this.speedUnityTime = speeds[i];
    button.innerHTML = this.speedUnityTime.text;

    if (this._playing) {
      this.pause();
      this.play();
    }
  },
  setTime(time) {
    this.map.time = time;
    if (this._timeSlider) this._timeSlider.value = +time;
    this._sliderChanged({
      type: 'change',
      target: { value: time },
    });
  },
  onAdd(map) {
    this.map = map;
    this._createDOM();
    this.setTime(this.interval.min);
    return this.container;
  },
  onRemove() {
    L.DomEvent.off(this._timeSlider, 'change input', this._sliderChanged, this);
    L.DomEvent.off(this._timeSlider, 'pointerdown mousedown touchstart', this._disableMapDragging, this);
    L.DomEvent.off(document, 'pointerup mouseup touchend', this._enableMapDragging, this);
  },

});

export default L.timelineSliderControl = (timeline, options) =>
  new L.TimelineSliderControl(timeline, options);
