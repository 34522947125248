import React, { useEffect } from 'react';
import { Admin, Resource, Loading } from 'react-admin';
import { Route } from 'react-router-dom';
import { createBrowserHistory } from "history";

import {
  // authProvider, 
  dataProvider,
  i18nProvider,
  dataFetch
} from './providers';

import Layout from './components/UI/Layout';
import history_map_reducer from './store/reducers/historyMap';
import live_map_reducer from './store/reducers/liveMap';
import page_reducer from './store/reducers/page';
import Dashboard from './containers/dashboard';
import DashboardAll from './containers/dashboardAll';
import {
  HistoryMap,
  LiveMap
} from './containers';

import Error from './Error';
import CatchAll from './CatchAll';

import './App.css';

import { usePage, useSetCampaign, useSetPage } from './store/pageHook';

const customRoutes = [
  <Route exact path="/live/:id" component={LiveMap} />,
  <Route exact path="/history/:id" component={HistoryMap} />,
];

const resources = [
  <Resource key={'launches'} name={'launches'} />
];

const App = () => {
  const history = createBrowserHistory();

  const { page, campaignId } = usePage();

  const setPage = useSetPage();
  const setCampaign = useSetCampaign();

  useEffect(() => {
    if (page) {
      dataFetch('GET', `/campaign/id/${page}`)
        .then(data => {
          if (!data || !data.id ) {
            setCampaign({id: -1});
          } else {
            // dispatch(showNotification('ra.page.error', 'warning'));
            setCampaign(data);
          }
        })
        .catch(error => {
          console.log(error)
          setCampaign({id: -1});
        })
    } else if (typeof page === 'string') {
      setCampaign({id: -2});
    } else if (page !== undefined) {
      setCampaign({id: -1});
    }
  }, [page]);

  useEffect(() => {
    if (campaignId === -1) {
      setTimeout(() => {
        history.replace('/');
        setCampaign({id: undefined});
        setPage(undefined);
      }, 1500);
    }
  }, [campaignId]);

  useEffect(() => {
    const _page = history.location.pathname.replace(/ /g, '').replace(/\//g, '').toLocaleLowerCase();
    setPage(_page);
  }, [history]);


  return (
      campaignId === -1 ?
        <Error />
        :
        campaignId === undefined ?
          <Loading loadingPrimary='Loading' loadingSecondary='We are validating the campaign' />
          :
          <Admin
            disableTelemetry
            dataProvider={dataProvider}
            i18nProvider={i18nProvider}
            layout={Layout}
            customReducers={{ history_map: history_map_reducer, live_map: live_map_reducer, page: page_reducer }}
            customRoutes={customRoutes}
            dashboard={campaignId === -2 ? DashboardAll : Dashboard}
            catchAll={CatchAll}
          >
            {resources}
          </Admin>

  );
}

export default App;
