// import { storage } from 'react-admin-loopback';
import configuration from '../configuration';

export default (method, path, headers = new Headers(), body) => 
  new Promise((resolve, reject)  => {
    // headers.append("Authorization",`${storage.load('lbtoken').id}`);
    headers.append("Access-Control-Allow-Origin",`*`);
    
    let goCatch = false;
    fetch(`${configuration.API_URL}${path}`,{
      method,
      headers,
      body
    })
    .then(resp => {
      if (resp.status === 200 || resp.status === 201)
        return resp.json();
      else if(resp.status === 204) 
        return undefined;
      else {
        goCatch = true;
        return resp.json();
      }
    })
    .then(data => {
      if (goCatch) {
        return reject(data);
      }
      else
        return resolve(data);
    })
    .catch(error => {
      reject(error);
    });
  });