import React, {
  useCallback,
  useMemo,
  Children,
  useState
} from 'react';

import { PageContext } from './Context';

const Provider = props => {
  const { children, campaignId, campaignName, page } = props;

  const [_campaign, _setCampaign] = useState({
    campaignId,
    campaignName
  });
  const [_page, _setPage] = useState(page);

  const setCampaign = useCallback(
    (p) => _setCampaign(_ => ({
        campaignId: p && p.id ? p.id : undefined,
        campaignName: p && p.name ? p.name : undefined
      })),
    [_setCampaign]
  );
  
  const setPage = useCallback(
    (newState) =>
      _setPage(_ => (newState)),
    [_setPage]
  );

  const value = useMemo(
    () => ({
      campaignId: _campaign.campaignId,
      campaignName: _campaign.campaignName,
      setCampaign,
      page: _page,
      setPage,
    }),
    [_campaign, setCampaign, _page, setPage]
  );

  return (
    <PageContext.Provider value={value}>
      {Children.only(children)}
    </PageContext.Provider>
  );
};

export default Provider;