import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import configuration from '../../configuration';

import { usePage } from '../../store/pageHook';


const useStyles = makeStyles(
  theme => ({
    img: {
      // height: '10em',
      maxWidth: '100%',
      height: '12em'
    },
    imgBig: {
      // height: '10em',
      maxWidth: '100%',
      height: '18em'
    },
  })
);

const mediaUrl = configuration.banner;

const Welcome = (props) => {
  const classes = useStyles(props);

  const { campaignName } = usePage();

  const isXSmall = useMediaQuery(theme =>
    theme.breakpoints.down('sm')
  );

  return (
    <Card>
      <CardMedia image={mediaUrl} className={isXSmall ? classes.img : classes.imgBig} />
      <CardContent>
        <Typography variant='body2' component='p'>
          A citizen science experiment. 
          <br />
          Data from the sensors is not yet validated.
          <br />
          <br />
          Campaign <b>{campaignName}</b>
        </Typography>
      </CardContent>
    </Card>
  )
};

export default Welcome;