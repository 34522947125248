import { useEffect, useState } from 'react';
import {
  Title,
  // useTranslate,
  fetchStart,
  fetchEnd,
  showNotification
} from 'react-admin';
import io from 'socket.io-client';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import MapIcon from '@material-ui/icons/Map';
import dataFetch from '../../providers/dataFetch';
import LeafletMap from '../../components/Map/Map';
import configuration from '../../configuration';
import { fetchOldObservations, liveObservations } from '../../store/actions/liveMap';
import OverviewControl from './OverviewControl';


const LiveMap = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  
  const { id } = useParams();
  
  const [launch, setLaunch] = useState(false);
  const [loaded, setLoaded] = useState(false);
  
  const observations = useSelector((state) => state.live_map);
  useEffect(() => {

    dispatch(fetchStart());
    dataFetch('GET', `/launches/${id}`)
      .then(data => {
        setLaunch(data);
        return dataFetch('GET', `/realtime/launch/${id}`)
      })
      .then(data => {
        dispatch(fetchOldObservations(data));
        setLoaded(true);
      })
      .catch(err => {
        // if (err.code === 'MODEL_NOT_FOUND') {
        //   history.replace('/');
        // }  
        // else {
          dispatch(showNotification('ra.page.error', 'warning'));
          setTimeout(() => history.replace('/'), 1500);
        // }
      })
      .finally(_ => dispatch(fetchEnd()));

    const socket = io(configuration.URL, {
      transports: ['websocket'/*, 'polling', 'flashsocket'*/],
      path: '/publicws'
    });

    socket.on('connect', () => {
      socket.emit('enter', { room: 'launch', id });
    });

    socket.on('error', (error) => {
      console.log("Socket error", error);
    });

    socket.on(`launch_${id}`, (data) => {
      dispatch(liveObservations(data));
    });

    return () => {
      socket.disconnect();
    }
  }, []);

  return (
    <div style={{ display: 'flex', flex: '1' }}>
      <Title title={(launch && launch.name) || ' '/* 'Wrong launch'*/} />
      <LeafletMap controlLocation={true} controlMeasure={true} realtime={loaded && observations} >
        {loaded && <OverviewControl /> }
      </LeafletMap>
    </div>
  );
};

export default LiveMap;