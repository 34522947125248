import {useEffect} from 'react';
import ReactDOMServer from 'react-dom/server';
import { useLeafletContext } from '@react-leaflet/core';
import L from 'leaflet';
import MeasureIcon from '@material-ui/icons/Straighten';

import './leaflet-measure';
import './leaflet-measure.css';

const MeasureControl = (props) => {
  const context = useLeafletContext();

  const customProps = {
    ...props,
    lineColor: 'red',    
    formatOutput: (distance, azimuth) => {
      let result = distance.toFixed(2) + ' m<br>' + (distance / 1852).toFixed(2) + ' M <br>' + azimuth.toFixed(2) + 'º'
      
      return result;
    },
    innerHtml: ReactDOMServer.renderToStaticMarkup(<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
      <MeasureIcon style={{
        transform: 'rotate(-45deg)',
        fontSize: '18px'
      }}/>
    </div>)
  }

  useEffect(() => {
    const container = context.layerContainer || context.map

    const control = L.measureControl(customProps);
    container.addControl(control);

    return () => {
      container.removeControl(control)
    }
  });

  return null;
};

export default MeasureControl;