import portugueseMessages from 'ra-language-portuguese-pt';

export default {
  ...portugueseMessages,
  resources: {
    institutions: {
      name: 'Instituição |||| Instituições',
      fields: {
        name: 'Nome',
        address: 'Endereço',
        phoneNumber: 'Contacto',
        acronym: "Acrónimo",
        url: "Sítio internet"
      }
    },
    users: {
      name: 'Utilizador |||| Utilizadores',
      fields: {
        username: 'Nome de Utilizador',
        name: 'Nome',
        email: 'E-Mail',
        institutionId: 'Instituição',
        active: 'Ativo',
        isAdmin: 'Administrador',
        role: 'Administrador'
      }
    },
    campaigns: {
      name: 'Campanha |||| Campanhas',
      fields: {
        name: 'Nome',
        description: 'Descrição',
        beginDate: 'Início',
        area: 'Área',
        zoom: 'Zoom',
        hide: 'Escondida'
      }
    },
    launches: {
      name: 'Lançamento |||| Lançamentos' ,
      fields: {
        beginTimestamp: 'Data de Início',
        endTimestamp: 'Data de Fim',
      },
    },
    equipment: {
      name: 'Equipamento |||| Equipamentos',
      fields: {
        state: "Estado",
        name: 'Nome',
        serialNumber: 'Número de Série',
        number: 'Número',
        equipmentmodelId: 'Modelo',
      }
    },
    datasets: {
      name: 'Dataset |||| Datasets',
      fields: {
        name: 'Nome',
        equipmentId: 'Equipamento',
        launchId: 'Lançamento',
        campaignId: 'Campanha',
        observationsNumb: 'Número de observações'
      },
    },
    equipmentmodels: {
      name: 'Modelo |||| Modelos',
      fields: {
        name: "Nome",
        equipmenttypeId: "Tipo de equipamento",
        observedProperty: 'Propriedades',
      },
    },
  },

  containers: {
    dashboard: {
      next_launches: 'Proximos Lançamentos',
      active_launches: 'Lançamentos ativos',
      past_launches: 'Lançamentos Passados',
      next_campaigns: 'Proximas Campanhas',
      active_campaigns: 'Campanhas Ativas',
      past_campaigns: 'Campanhas Passadas',
    },
    live: {
      name: 'Mapa ao Vivo',
      overview: 'Ultima transmissão'
    },
    history: {
      name: 'Mapa Histórico',
      complete_interval: 'Intervalo completo',
      all_data: 'Todos os dados',
      notifications: {
        no_data: 'Não existem dados'
      }
    },
    profile: {
      name: 'Perfil',
      fields: {
        name: 'Nome',
        username: 'Nome de utilizador', 
        email: 'Email',
        old_password: "Password antiga",
        new_password: "Nova password",
        repeat_password: "Repetir password",
      },
      buttons: {
        change_password: 'Mudar password',
      },
      notifications: {
        profile_updated: 'Perfil atualizado',
        password_updated: 'Password atualizada',
        wrong_password: 'Password antiga errada',
        on_failure_password: 'Error a mudar a password',
        on_failure_profile: 'Error a guardar perfil',
        different_passwords: 'Precisam de ser iguais',
      }
    },
    configuration: {
      name: 'Configuração',
      language: {
        name: 'Linguagem',
        'en-GB': 'English',
        en: 'English',
        pt: 'Português'
      },
      theme: {
        name: 'Tema',
        light: 'Claro',
        dark: 'Escuro',
      },
      notifications: {
        settings_updated: 'Configuração guardada',
        on_failure_settings_update: 'Erro a guardar configuração',
      }
    },
  }
};
