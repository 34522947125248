import { useEffect, useState, useRef } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

const ReallineControl = ({ realtime, controlOptions, controlRef }) => {
  const map = useMap();
  const groupLayers = useRef(); // arry  of group layers => reaname to groupLayers

  const [layersSerialNumber, setLayersSerialNumber] = useState({});
  const [started, setStarted] = useState(false); // Zoom
  
  const controlLayers = controlRef && controlRef.current;
  // console.log("ZOOM", map.getZoom())
  /*
    layersSerialNumber = {
      [serialNumber]: {
        marker_id,
        polyline_id,
        group
      }
    }
  */
  useEffect(() => {

    const _layersSerialNumber = {};
    groupLayers.current = realtime.map((equipment) => {
      const serialNumber = equipment.wavy.id;

      if (!layersSerialNumber[serialNumber]) { // create layer group
        const new_layer = createLayerGroup(serialNumber, equipment);
        _layersSerialNumber[serialNumber] = new_layer;
        return new_layer.group;
      }
      else {
        const { group, marker_id, polyline_id } = layersSerialNumber[serialNumber];

        if (equipment.position.length !== group.getLayer(polyline_id).getLatLngs().length) { // update layer group
          const path = equipment.position.map((pos) => pos.geometry.coordinates.slice().reverse());
          const popup = controlOptions.markerPopup({ id: serialNumber, ...equipment.wavy.last_transmission });

          group.getLayer(marker_id).setLatLng(path[path.length - 1]).bindPopup(popup);
          group.getLayer(polyline_id).setLatLngs(path);

          _layersSerialNumber[serialNumber] = { ...layersSerialNumber[serialNumber], ...group };
        }

        return group;
      }
    });

    setLayersSerialNumber({ ...layersSerialNumber, ..._layersSerialNumber });

    if (!started)
      setStarted(true);

  }, [realtime]);

  useEffect(() => {
    if (started) { // Zoom
      let latlngs = [];
      map.eachLayer(layer => {
        if ((layer instanceof L.Polyline) && (layer.getLatLngs().length > 0)) {
          latlngs = [...latlngs, ...layer.getLatLngs()];
        }
      });
            
      if (latlngs.length > 0) {
        map.fitBounds(latlngs, { padding: [100, 100], maxZoom: 15 });
      }
    }
  }, [started]);

  const createLayerGroup = (serialNumber, equipment) => {
    const path = equipment.position.map((pos) => pos.geometry.coordinates.slice().reverse());
    const popup = controlOptions.markerPopup({ id: serialNumber, ...equipment.wavy.last_transmission });
    const marker = controlOptions.marker(path[path.length - 1], equipment.wavy).bindPopup(popup);
    const polyline = L.polyline(path, { color: equipment.position[0].style.color });

    // const multiPoint = L.geoJSON(); // TODO: multipoint

    const group = L.layerGroup([marker]).addLayer(polyline);
    controlLayers.addOverlay(group, serialNumber).addTo(map);
    group.addTo(map);

    return { group, marker_id: group.getLayerId(marker), polyline_id: group.getLayerId(polyline) };
  }

  useEffect(() => {
    groupLayers.current = [];

    return () => {
      groupLayers.current.forEach((group) => {
        controlLayers.removeLayer(group);
        group.removeFrom(map);
      })
    }
  }, []);

  return null;
};

export default ReallineControl;
