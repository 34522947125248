import moment from 'moment';

export const toWavyGeojson = (data) => {
  return {
    wavy: {
      id: data.serialNumber,
      name: data.serialNumber,
      last_transmission: {...data}
    },
    position: [ObservationToFeature(data)]
  };
}

export const toWavyGeojsonOld = (data) => {

  let result = {};

  data.forEach(obs => {
    const { serialNumber } = obs;
    if (!result[serialNumber]) {
      result[serialNumber] = {
        wavy: {
          id: serialNumber,
          name: serialNumber,
          last_transmission: {}
        },
        position: []
      };
    } 
    result[serialNumber].wavy.last_transmission = obs;

    const pos = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [parseFloat(obs.position.lng), parseFloat(obs.position.lat)]
      },
      style: {
        color: obs.properties ? obs.properties.color : "rgb(51, 136, 255)"
      },
      properties: {
        id: serialNumber,
        key: serialNumber,
        timestamp: new Date(obs.timestamp).getTime()
      }
    };

    result[serialNumber].position.push(pos);
  });

  return Object.values(result);
}


// For now it will use the Observation keys
// The objective is to transform the array of objects in a GeoJson (Feature Collection)
export const toObservationData = (observations) => {
  let features = [];
  for (let i = 0; i < observations.length; i++) {
    features.push(ObservationToFeature(observations[i]));
  }

  return {
    type: "FeatureCollection",
    features: features
  }
};

export const toObservationDataSingleWAVY = (data) => {
  const { observations, observedProperties } = data;

  let features = [];
  for (let i = 0; i < observations.length; i++) {
    features.push(ObservationToFeature(observations[i], observedProperties));
  }

  return {
    type: "FeatureCollection",
    features: features
  }
};

const ObservationToFeature = (observation) => {
  // NOT observedProperties but equipmentModelProperties!!!
  const properties = {
    id: observation['serialNumber'],
    key: observation['serialNumber'],
    timestamp: moment(observation['timestamp']).unix() * 1000,
  };

  delete observation.timestamp;

  Object.keys(observation).forEach(key => {
    properties[key] = observation[key];
  });


  const color = observation.properties ? observation.properties.color : "rgb(51, 136, 255)";
  return {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [parseFloat(observation.position.lng), parseFloat(observation.position.lat)]
    },
    style: {
      color: color
    },
    properties: properties
  };
};