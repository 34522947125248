import { createContext } from 'react';

const PageContext = createContext({
  campaignId: undefined,
  campaignName: undefined,
  page: undefined,
  setCampaign: () => { },
  setPage: () => { }
});

PageContext.displayName = 'PageContext';

export { PageContext };