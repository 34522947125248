import React from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    card: {
      float: 'left',
      margin: '-20px 20px 0 15px',
      zIndex: 100,
      borderRadius: 3,
    },
    icon: {
      float: 'right',
      width: 54,
      height: 54,
      padding: 14,
      color: '#fff',
    }
  })
);

// style={{
//   width: 30,
//   height: 30,
//   handColor: '#f200dc',
//   wavyColor: '#f200dc'
// }}

const CardIcon = ({ Icon, cardIcon, bgColor, style, ...rest }) => {
  const classes = useStyles(rest);
  
  return (
  <Card className={cardIcon ? cardIcon : classes.card} style={{ backgroundColor: bgColor }}>
     <Icon {...style}/>
  </Card>
)};

export default CardIcon;
