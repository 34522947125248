import React from 'react';

export default (props) =>{
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width={props.width ? props.width : 78} height={props.height ? props.height : 78} viewBox="0 0 300 300" space="preserve">
    
        <circle fill={props.wavyColor ? props.wavyColor : "#E40000"} cx="150" cy="91" r="74.4"/>
        <path fill={props.handColor ? props.handColor : "#F8BA95"} d="M62.3,289c3.2,3.2,8.4,3.2,11.6,0c0,0,0,0,0,0l10.5-10.5c0,0,8.3-8.3,22.8-9.1c12.8-0.6,63.5-11.7,75.4-14.3
	c1.7-0.4,3.2-1.2,4.4-2.4l83.4-83.4c0,0,8-8-1.8-17.8s-17.8-1.1-17.8-1.1l-62,62c-1.4,1.4-3.3,2.3-5.2,2.4l-50.6,3.4
	c0,0-6.2,0.6-6.7-7.4c-0.4-6.2,6.9-6.9,6.9-6.9l38.7-3.6c0,0,12.8-0.4,11.2-14c-1.4-12.2-14.6-12.1-14.6-12.1l-73.1,1
	c0,0-16.7-2.5-35,15.8l-42.3,42.3c-3.2,3.2-3.2,8.3,0,11.5l0,0L62.3,289z"/>
        </svg>

    )
}