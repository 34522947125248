import { useState, useEffect, Fragment } from 'react';
import {
  Responsive,
  Title,
  showNotification,
  useQueryWithStore
} from 'react-admin';
import { useDispatch } from 'react-redux'

import Welcome from './Welcome';
import PublicNextCampaigns from './PublicNextCampaigns';
import PublicPastCampaigns from './PublicPastCampaigns';
import PublicActiveCampaigns from './PublicActiveCampaigns';

const styles = {
  flex: { display: 'flex', marginTop: '1em' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1/*, marginRight: '1em'*/ },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
  welcome: { width: '100%' }
};

const Dashboard = (props) => {
  const dispatch = useDispatch();

  const { loaded, error, data } = useQueryWithStore({
    type: 'getList',
    resource: `campaigns`,
  });

  useEffect(() => {

    if (loaded && !error) {
      setCampaigns(data);
    }
    else if (error) {
      dispatch(showNotification("http.fail_request", 'warning'));
    }
  }, [loaded, error, data])

  const [past_campaigns, setPastCampaigns] = useState([]);
  const [next_campaigns, setNextCampaigns] = useState([]);
  const [active_campaigns, setActiveCampaigns] = useState([]);

  const setCampaigns = (payload) => {
    const _next_campaigns = [];
    const _past_campaigns = [];
    const _active_campaigns = [];
    const now = new Date().getTime();

    payload.forEach(c => {
      const endtimestamp = new Date(c.lastDate).getTime();
      const begintimestamp = new Date(c.firstDate).getTime();

      const campaign = c;

      campaign.key = c.id;
      campaign.campaignId = c.id;
      campaign.id = c.id;
      campaign.campaignName = c.name;
      campaign.beginDate = c.begindate;
      campaign.permlink = c.permlink;

      if (begintimestamp > now) {
        _next_campaigns.push(campaign);
      }
      else if (!endtimestamp || endtimestamp === null || endtimestamp > now) {
        _active_campaigns.push(campaign);
      }
      else {
        _past_campaigns.push(campaign);
      }
    })

    setNextCampaigns(_next_campaigns);
    setActiveCampaigns(_active_campaigns);
    setPastCampaigns(_past_campaigns);
  }

  return (
    <Fragment>
      <Title title={process.env.REACT_APP_TITLE} />
      <Responsive
        small={
          <div>
            <div style={styles.flexColumn}>
              <div style={{ marginBottom: '2em' }}>
                <Welcome />
              </div>

              <div style={styles.flex}>
                <PublicActiveCampaigns mobile={true} value={active_campaigns} />
              </div>

              <div style={styles.flex}>
                <PublicPastCampaigns mobile={true} value={past_campaigns} />
              </div>

              <div style={styles.flex}>
                <PublicNextCampaigns mobile={true} value={next_campaigns} />
              </div>
            </div>
          </div>
        }
        medium={
          <div>
            <div style={styles.welcome}>
              <Welcome />
            </div>
            <div style={styles.flex}>
              <div style={styles.leftCol}>
                <div style={styles.flex}>
                  <PublicActiveCampaigns value={active_campaigns} />
                  <PublicPastCampaigns value={past_campaigns} />
                  <PublicNextCampaigns value={next_campaigns} />
                </div>
              </div>
            </div>
          </div>
        }
      />
    </Fragment>
  );
}

export default Dashboard;