import { useState, useEffect, Fragment } from 'react';
import {
  Responsive,
  Title,
  showNotification,
  useQueryWithStore
} from 'react-admin';
import { useDispatch } from 'react-redux'

import Welcome from './Welcome';
import PublicNextLaunches from './PublicNextLaunches';
import PublicPastLaunches from './PublicPastLaunches';
import PublicActiveLaunches from './PublicActiveLaunches';
// import configuration from '../../configuration';

import { usePage } from '../../store/pageHook';


const styles = {
  flex: { display: 'flex', marginTop: '1em' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1/*, marginRight: '1em'*/ },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
  welcome: { width: '100%' }
};

const Dashboard = (props) => {
  const dispatch = useDispatch();

  const {campaignId} = usePage();

  const { loaded, error, data } = useQueryWithStore({
    type: 'getList',
    resource: `launches/campaign/${campaignId}`,
  });

  useEffect(() => {

    if (loaded && !error) {
      setLaunches(data);
    }
    else if (error) {
      dispatch(showNotification("http.fail_request", 'warning'));
    }
  }, [loaded, error, data])

  const [past_launches, setPastLaunches] = useState([]);
  const [next_launches, setNextLaunches] = useState([]);
  const [active_launches, setActiveLaunches] = useState([]);

  const setLaunches = (payload) => {
    const  _next_launches = [];
    const  _past_launches = [];
    const  _active_launches = [];
    const now = new Date().getTime();

    payload.forEach(l => {
      const endtimestamp = new Date(l.endtimestamp).getTime();
      const begintimestamp = new Date(l.begintimestamp).getTime();

      const  launch = l;

      launch.key = l.id;
      launch.launch_id = l.id;
      launch.campaignId = l.Campaign.id;
      launch.campaignName = l.Campaign.name;

      if(begintimestamp > now) {
        _next_launches.push(launch);
      }
      else if (!endtimestamp || endtimestamp === null || endtimestamp > now) {
        _active_launches.push(launch);
      }
      else {
        _past_launches.push(launch);
      }
    })

    setNextLaunches(_next_launches);
    setActiveLaunches(_active_launches);
    setPastLaunches(_past_launches);
  }

  return (
    <Fragment>
      <Title title={process.env.REACT_APP_TITLE} />
      <Responsive
        small={
          <div>
            <div style={styles.flexColumn}>
              <div style={{ marginBottom: '2em' }}>
                <Welcome />
              </div>

              <div style={styles.flex}>
                <PublicActiveLaunches mobile={true} value={active_launches} />
              </div>
              
              <div style={styles.flex}>
                <PublicPastLaunches mobile={true} value={past_launches} />
              </div>

              <div style={styles.flex}>
                <PublicNextLaunches mobile={true} value={next_launches} />
              </div>
            </div>
          </div>
        }
        medium={
          <div>
            <div style={styles.welcome}>
              <Welcome />
            </div>
            <div style={styles.flex}>
              <div style={styles.leftCol}>
                <div style={styles.flex}>
                  <PublicActiveLaunches value={active_launches} />
                  <PublicPastLaunches value={past_launches} />
                  <PublicNextLaunches value={next_launches} />
                </div>
              </div>
            </div>
          </div>
        }
      />
    </Fragment>
  );
}

export default Dashboard;